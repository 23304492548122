import React, { useRef, useState, useEffect, useCallback } from 'react';
import { ArrowLeft, Download, Loader2 } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';
import { Calculator, Brain, Dumbbell, Leaf, Users, User, Eye, Music } from 'lucide-react';
import { jsPDF } from 'jspdf';
import 'jspdf/dist/polyfills.es.js';
import intelligenceData from './intelligenceData';
import ActivitiesList from './activitiesList';
import { PulseLoader } from 'react-spinners';
import { Mail, Check, AlertCircle } from 'lucide-react';
import { put } from '@vercel/blob';
import { Info } from 'lucide-react';

const API_BASE_URL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:3000' 
  : '';

const useApi = (endpoint, requestData = null) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${API_BASE_URL}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setData(result);
      setError(null);
    } catch (error) {
      console.error(`Error fetching from ${endpoint}:`, error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (requestData) {
      fetchData();
    }
  }, [endpoint, JSON.stringify(requestData)]);

  return { data, error, isLoading, refetch: fetchData };
};


const AICarePlan = ({ data, onClose }) => {

  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isEmailSending, setIsEmailSending] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [shouldSendEmail, setShouldSendEmail] = useState(true); // Set to true by default to enable auto-sending
  const [emailRecipient] = useState('melissamirabello@cfhdc.com'); // Default test email
  const [uploadStatus, setUploadStatus] = useState({
    isUploading: false,
    isComplete: false,
    error: null,
    htmlUrl: null
  });
  
  const [emailStatus, setEmailStatus] = useState({
    isSending: false,
    isSent: false,
    error: null,
    timestamp: null
  });

const NumberedCircle = ({ number }) => (
  <div className="flex items-center justify-center min-w-[24px] h-6 w-6 rounded-full bg-purple-100 border border-purple-300 text-purple-700 text-sm font-medium">
    {number}
  </div>
);

const InsightItem = ({ point, rationale, number }) => (
  <div className="mb-6">
    <div className="flex items-start gap-4">
      <NumberedCircle number={number} />
      <div className="flex-1">
        <p className="text-gray-800 font-medium mb-2">{point}</p>
        <p className="text-gray-600 italic text-sm pl-2 border-l-2 border-gray-200">
          Rationale: {rationale}
        </p>
      </div>
    </div>
  </div>
);
  const reportRef = useRef(null);

  const [errors, setErrors] = useState({});

  const { 
    data: summaryData, 
    error: summaryError, 
    isLoading: summaryLoading 
  } = useApi('api/generate-summary', {
    bioData: data.bioSummaryData,
    clientInfo: data.clientInfo
  });

  const { 
    data: recommendationsData, 
    error: recommendationsError, 
    isLoading: recommendationsLoading 
  } = useApi('api/generate-recommendations', {
    bioData: data.bioSummaryData,
    topIntelligences: data.topIntelligences.map(i => i.name),
    clientInfo: data.clientInfo 
  });


  // State for insights (needs special handling due to multiple calls)
  const [insightsLoading, setInsightsLoading] = useState(true);
  const [insightsError, setInsightsError] = useState(null);

  const [summary, setSummary] = useState('');
  const [personalizedInsights, setPersonalizedInsights] = useState({});
  const [recommendations, setRecommendations] = useState('');
  const [loading, setLoading] = useState({
    summary: true,
    insights: true,
    recommendations: true
  });

  const LoadingSection = ({ text }) => (
    <div className="flex items-center justify-center py-6 space-x-3">
      <PulseLoader color="#9333ea" size={10} speedMultiplier={0.8} />
      <span className="ml-3 text-gray-600 font-medium">{text}</span>
    </div>
  );

  // Error component
  const ErrorSection = ({ message }) => (
    <div className="p-4 bg-red-50 border border-red-200 rounded-lg">
      <p className="text-red-600">Unable to load data: {message}</p>
    </div>
  );


   // Fetch insights for each intelligence type
   useEffect(() => {
    const fetchInsights = async () => {
      setInsightsLoading(true);
      try {
        const insightsData = {};
        
        // Fetch insights for primary intelligence
        const primaryResponse = await fetch(`${API_BASE_URL}/api/generate-care-insights-primary`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            bioData: data.bioSummaryData,
            intelligence: data.topIntelligences[0].name,
            intelligenceAnswers: data.firstIntelligenceAnswers,
            clientInfo: data.clientInfo 
          })
        });

        // Fetch insights for secondary intelligence
        const secondaryResponse = await fetch(`${API_BASE_URL}/api/generate-care-insights-secondary`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            bioData: data.bioSummaryData,
            intelligence: data.topIntelligences[1].name,
            intelligenceAnswers: data.secondIntelligenceAnswers,
            clientInfo: data.clientInfo 
          })
        });

        if (!primaryResponse.ok || !secondaryResponse.ok) {
          throw new Error('Failed to fetch insights');
        }

        const primaryData = await primaryResponse.json();
        const secondaryData = await secondaryResponse.json();

        insightsData[data.topIntelligences[0].name.replace(' Intelligence', '')] = 
          primaryData.insights.insights || [];
        insightsData[data.topIntelligences[1].name.replace(' Intelligence', '')] = 
          secondaryData.insights.insights || [];

        setPersonalizedInsights(insightsData);
        setInsightsError(null);
      } catch (error) {
        console.error('Error fetching insights:', error);
        setInsightsError(error.message);
      } finally {
        setInsightsLoading(false);
      }
    };

    if (data.topIntelligences?.length > 0) {
      fetchInsights();
    }
  }, [data.topIntelligences, data.bioSummaryData, data.firstIntelligenceAnswers, data.secondIntelligenceAnswers]);


  const interpersonalScore = data.intelligenceScores.find(score => score.name === 'Interpersonal Intelligence')?.percentage || 0;
  const intrapersonalScore = data.intelligenceScores.find(score => score.name === 'Intrapersonal Intelligence')?.percentage || 0;
  
  let interAndIntraPersonalData = [
    { name: 'Interpersonal', value: interpersonalScore, fill: '#D8B4BC' },
    { name: 'Intrapersonal', value: intrapersonalScore, fill: '#808080' }
  ];

  const getIntelligenceComparisonMessage = (data) => {
    const interpersonalScore = data.intelligenceScores.find(
      score => score.name === 'Interpersonal Intelligence'
    )?.percentage || 0;
    
    const intrapersonalScore = data.intelligenceScores.find(
      score => score.name === 'Intrapersonal Intelligence'
    )?.percentage || 0;
    
    const firstName = data.clientInfo.name.split(' ')[0];
  
    if (interpersonalScore > intrapersonalScore) {
      return `${firstName} possesses a higher level of Interpersonal Intelligence (${interpersonalScore.toFixed(1)}%) than Intrapersonal Intelligence (${intrapersonalScore.toFixed(1)}%). This higher interpersonal score means that ${firstName} is more likely to be successful when interacting with others. People with strong interpersonal intelligence often thrive in group activities, respond well to social cues, and find comfort in companionship. For ${firstName}, this suggests that care approaches should emphasize social engagement, group activities, and opportunities for meaningful interactions with others. Caregivers should encourage participation in small group activities, facilitate social connections with other residents, and use social interaction as a means of comfort during periods of distress. However, it's important to note that while ${firstName}'s Intrapersonal score is lower (${intrapersonalScore.toFixed(1)}%), some quiet time for self-reflection and independent activities should still be incorporated into the daily routine to maintain a balanced approach to care.`;
    } else if (intrapersonalScore > interpersonalScore) {
      return `${firstName} possesses a higher level of Intrapersonal Intelligence (${intrapersonalScore.toFixed(1)}%) than Interpersonal Intelligence (${interpersonalScore.toFixed(1)}%). This higher intrapersonal score means that ${firstName} is more likely to be successful when engaging in self-directed, independent activities. People with strong intrapersonal intelligence typically have a deep understanding of their own needs, preferences, and emotions, and may process experiences better through self-reflection. For ${firstName}, this suggests that care approaches should respect the need for personal space and independent decision-making. Activities should include opportunities for individual pursuits, quiet reflection, and self-directed tasks. Caregivers should be mindful to provide adequate alone time and not mistake a preference for solitude as social withdrawal. While ${firstName}'s Interpersonal score is lower (${interpersonalScore.toFixed(1)}%), gentle encouragement for selective social engagement can still be beneficial, but should be balanced with respect for personal boundaries and the need for independence.`;
    } else {
      return `${firstName} shows equal levels of Interpersonal and Intrapersonal Intelligence (${interpersonalScore.toFixed(1)}%). This balance suggests that ${firstName} may be equally comfortable with both social interactions and independent activities. This unique balance indicates adaptability and flexibility in engaging with different types of experiences. For optimal care, caregivers should alternate between group activities and solitary pursuits, paying careful attention to ${firstName}'s cues about which type of engagement is preferred at any given time. This balanced profile suggests that ${firstName} might benefit from a varied daily routine that includes both social interaction and quiet, reflective periods. Care approaches should be flexible, allowing ${firstName} to choose between group activities and independent pursuits based on current mood and energy levels.`;
    }
  };

  const maxValue = Math.max(...interAndIntraPersonalData.map(d => d.value));
  interAndIntraPersonalData = interAndIntraPersonalData.map(d => ({
    ...d,
    fill: d.value === maxValue ? '#6b21a8' : '#dbdbdb'
  }));

  

  function getIconForIntelligence(name) {
    switch (name) {
      case 'Logical-Mathematical':
        return <Calculator className="w-5 h-5 text-purple-700 inline-block mr-2" />;
      case 'Linguistic-Verbal':
        return <Brain className="w-5 h-5 text-purple-700 inline-block mr-2" />;
      case 'Bodily-Kinesthetic':
        return <Dumbbell className="w-5 h-5 text-purple-700 inline-block mr-2" />;
      case 'Naturalistic':
        return <Leaf className="w-5 h-5 text-purple-700 inline-block mr-2" />;
      case 'Interpersonal':
        return <Users className="w-5 h-5 text-purple-700 inline-block mr-2" />;
      case 'Intrapersonal':
        return <User className="w-5 h-5 text-purple-700 inline-block mr-2" />;
      case 'Visual-Spatial':
        return <Eye className="w-5 h-5 text-purple-700 inline-block mr-2" />;
      case 'Musical':
        return <Music className="w-5 h-5 text-purple-700 inline-block mr-2" />;
      default:
        return null;
    }
  }

  const getIntelligenceColor = (name) => {
    const colorMap = {
      'Logical-Mathematical Intelligence': '#D2B48C',
      'Linguistic-Verbal Intelligence': '#9D7FD9',
      'Bodily-Kinesthetic Intelligence': '#F6E05E',
      'Naturalistic Intelligence': '#68D391',
      'Interpersonal Intelligence': '#F8B4B4',
      'Intrapersonal Intelligence': '#A0AEC0',
      'Visual-Spatial Intelligence': '#63B3ED',
      'Musical Intelligence': '#ffb570'
    };
    return colorMap[name] || '#808080';
};

const allIntelligencesData = data.intelligenceScores.map(score => ({
    name: score.name.replace(' Intelligence', ''),
    Percentage: score.percentage,
    fill: getIntelligenceColor(score.name)
}));

  const [isExporting, setIsExporting] = useState(false);
  const [showExportOptions, setShowExportOptions] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (showExportOptions && !event.target.closest('.export-dropdown')) {
        setShowExportOptions(false);
      }
    }
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showExportOptions]);

  const handleExportPdf = async () => {
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'pt',
      format: 'letter',
      encryption: {
        userPassword: "CFHDC_MI_Survey_2024_Secure_Results_83932152!@",
        ownerPassword: "CFHDC_MI_Survey_2024_Secure_Results_83932152!@",
        userPermissions: ["print", "modify", "copy", "annot-forms"]
      }
    });
  
    const element = reportRef.current;
    if (!element) return;
  
    // Show a loading indicator
    setIsExporting(true);
  
    try {
      // Improved html2canvas options
      await doc.html(element, {
        x: 10,
        y: 10,
        width: doc.internal.pageSize.getWidth() - 20, // Account for margins
        windowWidth: element.offsetWidth,
        autoPaging: 'text',
        html2canvas: {
          scale: 2, // Higher scale for better quality
          useCORS: true,
          logging: false,
          letterRendering: true,
          allowTaint: true,
          foreignObjectRendering: true
        },
        callback: function(doc) {
          doc.save('MISPWD_Care_Plan.pdf');
          setIsExporting(false);
        }
      });
    } catch (error) {
      console.error('PDF export error:', error);
      setIsExporting(false);
      alert('There was an issue exporting the PDF. Please try the HTML export option.');
    }
  };

  const allContentLoaded = !summaryLoading && !recommendationsLoading && !insightsLoading;
  
  const sendResultsEmail = useCallback(async (htmlUrl) => {
    // Validate HTML URL exists before proceeding
    if (!htmlUrl) {
      console.error("Cannot send email: Missing HTML URL");
      setEmailStatus({
        isSending: false,
        isSent: false,
        error: "Missing HTML file link. Please try again.",
        timestamp: new Date().toISOString()
      });
      return;
    }
  
    // Skip if already sending or already sent
    if (emailStatus.isSending || emailStatus.isSent) {
      console.log("Email already sending or sent, skipping duplicate send");
      return;
    }
    
    // Update status to sending
    setEmailStatus({
      isSending: true,
      isSent: false,
      error: null,
      timestamp: new Date().toISOString()
    });
    
    try {
      console.log('Sending results email with HTML link:', htmlUrl);
      
      // Send email via API
      const response = await fetch('/api/send-complete-results', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          clientInfo: {
            name: data.clientInfo.name,
            respondent: data.clientInfo.respondent,
            date: data.clientInfo.date
          },
          htmlUrl: htmlUrl
        }),
      });
      
      // Parse response before checking status to ensure we have any error details
      const result = await response.json();
      
      if (!response.ok) {
        throw new Error(result.error || `Server responded with ${response.status}`);
      }
      
      console.log('Email sent successfully:', result);
      
      // Update status to sent
      setEmailStatus({
        isSending: false,
        isSent: true,
        error: null,
        timestamp: new Date().toISOString()
      });
      
    } catch (error) {
      console.error('Error sending results email:', error);
      
      // Update status to error
      setEmailStatus({
        isSending: false,
        isSent: false,
        error: error.message || "Failed to send email",
        timestamp: new Date().toISOString()
      });
    }
  }, [data.clientInfo, emailStatus.isSending, emailStatus.isSent, setEmailStatus]);

  // HTML EXPORT
  
  const handleExportHtml = useCallback(async () => {
    const element = reportRef.current;
    if (!element) return;
    
    // Check if all content is loaded before exporting
    if (!allContentLoaded) {
      alert('Content is still generating. Please wait until all content has loaded.');
      return;
    }
    
    // Show loading indicators
    setIsExporting(true);
    setUploadStatus({
      isUploading: true,
      isComplete: false,
      error: null,
      htmlUrl: null
    });
    
    let htmlContent = '';

    try {
      // Clone the element to avoid modifying the original
      const clonedElement = element.cloneNode(true);
      
      // Create a new document
      const newDocument = document.implementation.createHTMLDocument('MISPWD Care Plan');
      
      // Add necessary styles
      const styleElement = document.createElement('style');
      
      // Copy all styles from the current document
      Array.from(document.styleSheets).forEach(sheet => {
        try {
          Array.from(sheet.cssRules || []).forEach(rule => {
            styleElement.appendChild(document.createTextNode(rule.cssText));
          });
        } catch (e) {
          console.warn('Could not access stylesheet', e);
        }
      });
      
      // Add some additional print styles
      styleElement.appendChild(document.createTextNode(`
        @media print {
          body { margin: 0; padding: 20px; }
          .no-print { display: none !important; }
        }
        body { font-family: Arial, sans-serif; }
      `));

      styleElement.appendChild(document.createTextNode(`
        /* Fallback styles if external styles fail to load */
        body { font-family: Arial, sans-serif; color: #333; line-height: 1.6; }
        h1, h2, h3 { color: #6b21a8; margin-bottom: 1rem; }
        p { margin-bottom: 1rem; }
        .text-gray-700 { color: #4a5568; }
        .text-purple-800 { color: #6b21a8; }
        .text-teal-600 { color: #319795; }
        .font-bold { font-weight: bold; }
        .font-semibold { font-weight: 600; }
        .border-b { border-bottom: 1px solid #e2e8f0; }
        .mt-10 { margin-top: 2.5rem; }
        .mb-4 { margin-bottom: 1rem; }
        .p-6 { padding: 1.5rem; }
      `));
      
      newDocument.head.appendChild(styleElement);
      
      // Add necessary external stylesheets
      const linkElement = document.createElement('link');
      linkElement.rel = 'stylesheet';
      linkElement.href = 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap';
      newDocument.head.appendChild(linkElement);
      
      // Add the cloned content
      newDocument.body.appendChild(clonedElement);
      
      // Remove any no-print elements
      const noPrintElements = newDocument.querySelectorAll('.no-print');
      noPrintElements.forEach(el => el.remove());
      
      // Get HTML content
      htmlContent = newDocument.documentElement.outerHTML;
      
      // Create file name
      const fileName = `MISPWD_Care_Plan_${data.clientInfo.name.replace(/\s+/g, '_')}.html`;
      
      // Get client ID from localStorage or generate new one
      const clientId = window.cfhdc?.clientId || 
                      `${data.clientInfo.name.replace(/\s+/g, '_')}_${data.clientInfo.date}`;
      
      // Upload to Vercel Blob
      const response = await fetch('/api/secure-file', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fileType: 'html',
          content: htmlContent,
          clientName: data.clientInfo.name,
          fileName: fileName,
          metadata: {
            respondentName: data.clientInfo.respondent,
            surveyorName: data.clientInfo.surveyor,
            date: data.clientInfo.date
          }
        }),
      });
      
      if (!response.ok) {
        throw new Error(`Server responded with ${response.status}`);
      }
      
      const result = await response.json();
      console.log('Uploaded HTML to secure storage:', result);
      
      // Store in window object for later access
      window.cfhdc = window.cfhdc || {};
      window.cfhdc.clientId = clientId;
      window.cfhdc.htmlCarePlanUrl = result.url;
      
      // Update upload status
      setUploadStatus({
        isUploading: false,
        isComplete: true,
        error: null,
        htmlUrl: result.url
      });
      
      // Check if we have PDF URL as well
      const pdfUrl = window.cfhdc?.pdfResultsUrl || localStorage.getItem('cfhdc_pdf_results_url');
      
      if (pdfUrl) {
        // Try to send email with both links
        sendResultsEmail(pdfUrl, result.url);
      }
      
      // Also provide local download as backup
      const blob = new Blob([htmlContent], { type: 'text/html' });
      const url = URL.createObjectURL(blob);
      
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    
    } catch (error) {
      console.error('HTML export/upload error:', error);
      
      setUploadStatus({
        isUploading: false,
        isComplete: false,
        error: error.message,
        htmlUrl: null
      });
      
      // Still provide local download as fallback
      const blob = new Blob([htmlContent], { type: 'text/html' });
      const url = URL.createObjectURL(blob);
      
      const a = document.createElement('a');
      a.href = url;
      a.download = `MISPWD_Care_Plan_${data.clientInfo.name.replace(/\s+/g, '_')}.html`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
      
      alert('There was an issue with secure storage. A local copy has been downloaded.');
    } finally {
      setIsExporting(false);
    }
  }, [data, allContentLoaded, reportRef, setIsExporting, setUploadStatus, sendResultsEmail]);;


  const EmailStatusDisplay = () => {
    if (!uploadStatus.htmlUrl) return null;
    
    if (emailStatus.isSending) {
      return (
        <div className="flex items-center mt-4 p-3 bg-blue-50 border border-blue-300 rounded">
          <Loader2 className="w-5 h-5 text-blue-500 animate-spin mr-2" />
          <span className="text-blue-700">Sending care plan to the care team...</span>
        </div>
      );
    }
    
    if (emailStatus.isSent) {
      return (
        <div className="flex items-center mt-4 p-3 bg-green-50 border border-green-300 rounded">
          <Check className="w-5 h-5 text-green-500 mr-2" />
          <span className="text-green-700">Care plan sent to the care team</span>
        </div>
      );
    }
    
    if (emailStatus.error) {
      return (
        <div className="flex items-center mt-4 p-3 bg-red-50 border border-red-300 rounded">
          <AlertCircle className="w-5 h-5 text-red-500 mr-2" />
          <span className="text-red-700">Error sending care plan: {emailStatus.error}</span>
          <button 
            onClick={() => sendResultsEmail(uploadStatus.htmlUrl)}
            className="ml-2 px-2 py-1 bg-red-100 text-red-700 text-sm rounded hover:bg-red-200"
          >
            Retry
          </button>
        </div>
      );
    }
    
    return (
      <div className="flex items-center mt-4 p-3 bg-gray-50 border border-gray-300 rounded">
        <Info className="w-5 h-5 text-gray-500 mr-2" />
        <span className="text-gray-700">Care plan stored securely</span>
        <button 
          onClick={() => sendResultsEmail(uploadStatus.htmlUrl)}
          className="ml-2 px-2 py-1 bg-blue-100 text-blue-700 text-sm rounded hover:bg-blue-200"
        >
          Send to Care Team
        </button>
      </div>
    );
  };

// Replace the existing useEffect for email sending with this improved version
useEffect(() => {
  // Auto-upload HTML when content is loaded
  if (allContentLoaded && !isExporting && !uploadStatus.isComplete && !uploadStatus.isUploading) {
    console.log('[AUTO EXPORT] All content loaded, automatically triggering export...');
    
    // Add a small delay to ensure DOM is fully rendered
    const timer = setTimeout(() => {
      handleExportHtml();
    }, 2000);
    
    return () => clearTimeout(timer);
  }
  
  // Automatically send email when HTML is uploaded successfully
  if (uploadStatus.isComplete && uploadStatus.htmlUrl && !emailStatus.isSending && !emailStatus.isSent) {
    console.log('[AUTO EMAIL] HTML upload complete, automatically sending email...');
    
    // Add a small delay to ensure everything is settled
    const timer = setTimeout(() => {
      try {
        console.log('[AUTO EMAIL] Attempting to send email with HTML URL:', uploadStatus.htmlUrl);
        sendResultsEmail(uploadStatus.htmlUrl);
      } catch (error) {
        console.error('[AUTO EMAIL] Error triggering email send:', error);
      }
    }, 1000);
    
    return () => clearTimeout(timer);
  }
}, [
  allContentLoaded, 
  isExporting, 
  uploadStatus.isComplete, 
  uploadStatus.isUploading,
  uploadStatus.htmlUrl,
  emailStatus.isSending,
  emailStatus.isSent,
  handleExportHtml,
  sendResultsEmail
]);


  return (

    
    <div className="min-h-screen w-full bg-white flex flex-col">
      <style>
        {`
          @media print {
            .no-print {
              display: none !important;
            }
            body {
              -webkit-print-color-adjust: exact;
              print-color-adjust: exact;
              background: #fff;
            }
          }
        `}
      </style>

 {/* Header & Back/Download Buttons */}
<div className="flex items-center justify-between px-6 py-4 md:px-10 md:py-6 no-print">
  <button
    onClick={onClose}
    className="flex items-center gap-2 text-gray-500 rounded-full px-3 py-2 hover:text-gray-700 transition-colors hover:bg-gray-100"
  >
    <ArrowLeft className="w-5 h-5" />
    <span className="text-sm font-semibold">Back to Results</span>
  </button>

  <div className="relative inline-block export-dropdown">
  <button
    onClick={() => setShowExportOptions(!showExportOptions)}
    className={`flex items-center gap-2 rounded-full px-3 py-2 transition-colors ${
      !allContentLoaded
        ? 'bg-gray-200 text-gray-400 cursor-not-allowed'
        : 'text-gray-500 hover:text-gray-700 hover:bg-gray-100'
    }`}
    disabled={!allContentLoaded || isExporting}
    title={!allContentLoaded ? 'Please wait for content to finish generating' : 'Export options'}
  >
    {isExporting ? (
      <Loader2 className="w-5 h-5 animate-spin" />
    ) : (
      <Download className="w-5 h-5" />
    )}
    <span className="text-sm font-semibold">
      {isExporting ? 'Exporting...' : 'Export'}
    </span>
    {!allContentLoaded && (
      <span className="ml-2 text-xs bg-blue-100 text-blue-800 px-2 py-0.5 rounded-full">
        Generating...
      </span>
    )}
  </button>
  
  {showExportOptions && allContentLoaded && (
    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
      <div className="py-1">
        {/*<button
          onClick={() => {
            handleExportPdf();
            setShowExportOptions(false);
          }}
          disabled={isExporting}
          className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        >
          {isExporting ? 'Exporting PDF...' : 'Download as PDF'}
        </button>*/}
        <button
          onClick={() => {
            handleExportHtml();
            setShowExportOptions(false);
          }}
          className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
        >
          {isExporting ? 'Exporting...' : 'Download care plan'}
        </button> 
        
        {/* Status indicators */}
        {uploadStatus.isComplete && uploadStatus.htmlUrl && (
          <div className="px-4 py-2 text-xs text-green-600 border-t border-gray-100">
            <div className="flex items-center mb-1">
              <Check className="w-3 h-3 mr-1" />
              <span>Care Plan securely stored</span>
            </div>
          </div>
        )}
        
        {window.cfhdc?.pdfResultsUrl && (
          <div className="px-4 py-2 text-xs text-green-600 border-t border-gray-100">
            <div className="flex items-center mb-1">
              <Check className="w-3 h-3 mr-1" />
              <span>Results sent to care team</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )}
</div>
  </div>

      {/* Main Container for PDF */}
      <div ref={reportRef} className="w-[95%] max-w-5xl mx-auto mb-12 bg-white shadow-xl rounded-xl overflow-hidden p-6 md:p-10">
        {/* Header Section */}
        <div className="pb-8 border-b border-gray-200">
          <div className="space-y-4 text-center">
            <h1 className="text-3xl md:text-4xl font-bold text-purple-800 tracking-tight">
              The Center for Holistic Dementia Care
            </h1>
            <h2 className="text-teal-600 text-xl md:text-2xl font-medium">
              The Multiple Intelligence Survey for Persons with Dementia (MISPWD) Results
            </h2>
  
          </div>

          {/* Client Info Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-4 gap-x-6 mt-8 border-t border-gray-200 pt-10">
          <div>
          <span className="font-semibold text-gray-700">Person this survey is about: </span>
          <span className="text-gray-900">{data.clientInfo.name}</span>
        </div>
        <div>
          <span className="font-semibold text-gray-700">Person answering the questions for the client </span>
          <span className="text-gray-900">{data.clientInfo.respondent}</span>
        </div>
        {/*<div className="hidden">
          <span className="font-semibold text-gray-700">Surveyor: </span>
          <span className="text-gray-900">{data.clientInfo.surveyor}</span>
        </div>*/}
        <div>
          <span className="font-semibold text-gray-700">Date: </span>
          <span className="text-gray-900">{data.clientInfo.date}</span>
        </div>
        </div>

        {/* Overview Section */}
        <div className="mt-10 space-y-6">
          <h2 className="text-purple-800 text-2xl font-bold">Overview of MISPWD Survey Goals</h2>
          <p className="text-gray-700 leading-relaxed">
            The Multiple Intelligence Survey for Persons with Dementia (MISPWD) eliminates the
            guesswork in care by identifying person-centered strategies, helping create the ideal care
            plan. The survey's most notable benefit is its ability to pinpoint the following:
          </p>

          <div className="space-y-6">
            <section>
              <h3 className="text-teal-600 text-xl font-semibold mb-2">Comfortable Environments:</h3>
              <p className="text-gray-700">
                Intelligence-specific surroundings where people feel safe and secure to help them thrive.
              </p>
            </section>

            <section>
              <h3 className="text-teal-600 text-xl font-semibold mb-2">Augmentative and Alternative Communication (AAC) Strategies:</h3>
              <p className="text-gray-700">
                Intelligence-specific strategies to help reinforce oral language for those who have trouble
                communicating verbally and methods that provide nonverbal people with a means to relay
                information.
              </p>
            </section>

            <section>
              <h3 className="text-teal-600 text-xl font-semibold mb-2">Suitable Activities:</h3>
              <p className="text-gray-700">
                Intelligence-specific activities that align with how people learn and acquire information
                throughout their lives, increasing their chances of success.
              </p>
            </section>

            <section>
              <h3 className="text-teal-600 text-xl font-semibold mb-2">Potential Benefits:</h3>
              <ul className="list-disc pl-6 text-gray-700 space-y-2">
                <li>Increased independence</li>
                <li>Decreased troublesome symptoms, e.g., confusion, anxiety, and agitation</li>
                <li>Improved self-worth</li>
                <li>Better ability to get needs and wants met</li>
                <li>Improved mental functioning</li>
              </ul>
            </section>
          </div>
        </div>

      {/* Summary Section */}
    <div className="mt-10 space-y-6">
      <h2 className="text-purple-800 text-2xl font-bold mb-4">
        Brief Summary about {data?.clientInfo?.name || 'Client'}
      </h2>
      
      {summaryLoading ? (
        <LoadingSection text="Generating personalized summary..." />
      ) : summaryError ? (
        <ErrorSection message={summaryError} />
      ) : summaryData?.summary ? (
        <div className="text-gray-700 leading-relaxed">
          {summaryData.summary}
        </div>
      ) : (
        <p className="text-gray-500 italic">No summary available.</p>
      )}
    </div>



        {/* Survey Results Section */}
        <div className="mt-10 space-y-8">
          <h2 className="text-purple-800 text-2xl font-bold">Survey Results</h2>

          {/* Intelligence Scores */}
          {/* Intelligence Scores */}
<div className="grid grid-cols-1 md:grid-cols-2 gap-8">
  <div className="text-xl font-semibold text-gray-800">
    Interpersonal Intelligence: <span className="text-purple-700">{interpersonalScore.toFixed(1)}/100</span>
  </div>
  <div className="text-xl font-semibold text-gray-800">
    Intrapersonal Intelligence: <span className="text-purple-700">{intrapersonalScore.toFixed(1)}/100</span>
  </div>
</div>

          {/* Chart */}
          <div className="mt-12 space-y-6">
            <div className="h-[400px] mb-8 border border-gray-200 p-4 rounded-lg overflow-visible">
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  data={interAndIntraPersonalData}
                  layout="vertical"
                  margin={{ top: 20, right: 30, left: 100, bottom: 40 }}
                >
                  <CartesianGrid horizontal={false} stroke="#eeeeee" />
                  <XAxis
                    type="number"
                    domain={[0, 100]}
                    ticks={[0, 25, 50, 75, 100]}
                    stroke="#000"
                    label={{ value: 'Percentage', position: 'bottom', offset: 10 }}
                  />
                  <YAxis
                    type="category"
                    dataKey="name"
                    width={100}
                    stroke="#000"
                    tick={{ fontWeight: 'bold' }}
                  />
                  <Bar
                    dataKey="value"
                    barSize={100}
                    label={{ position: 'right', fill: '#000', fontSize: 14 }}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          <p className="text-gray-700 leading-relaxed">
            {getIntelligenceComparisonMessage(data)}
          </p>
        </div>

  {/* Top 2 Intelligences */}
<div className="mt-10 space-y-8">
  <h2 className="text-purple-800 text-2xl font-bold">Top 2 Intelligences and MĪ Care Plan Recommendations</h2>
  {/* Purple Contact Section */}
<div className="bg-purple-50 p-8">
  <div className="flex items-center flex-wrap gap-2">
    <a 
      href="https://cfhdc.com/contact/" 
      target="_blank" 
      rel="noreferrer" 
      className="inline-block bg-purple-700 hover:bg-purple-800 text-white font-medium py-2 px-4 rounded-lg transition-colors duration-200"
    >
      Contact us
    </a>
    <p className="text-purple-700">if you are interested in purchasing our tailor-made activities for your loved one.</p>
  </div>
</div>

{/* Blue Guides */}
<div className="bg-blue-50 p-8">
  <div className="flex items-center flex-wrap gap-2">
    <a 
      href="https://mi-care-tips.webflow.io/" 
      target="_blank" 
      rel="noreferrer" 
      className="inline-block bg-blue-700 hover:bg-blue-800 text-white font-medium py-2 px-4 rounded-lg transition-colors duration-200"
    >
      Read some guides
    </a>
    <p className="text-blue-700">to help you easily implement these recommendations.</p>
  </div>
</div>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
    {data.topIntelligences.map((intelligence, index) => {
      const intelligenceName = intelligence.name.replace(' Intelligence', '');
      const intelligenceInfo = intelligenceData[intelligenceName];
      const isFirstIntelligence = index === 0;
      const endpointUrl = isFirstIntelligence ? 
        '/api/generate-care-insights-primary' : 
        '/api/generate-care-insights-secondary';
      const intelligenceAnswers = isFirstIntelligence ? 
        data.firstIntelligenceAnswers : 
        data.secondIntelligenceAnswers;

      return (
        <div key={index} className="border border-gray-300 rounded-lg bg-white shadow-sm">
          {/* Intelligence header and basic info - unchanged */}
          <div className="border-b border-gray-300 p-4">
            <h3 className="font-bold text-gray-800">
              {getIconForIntelligence(intelligenceName)}
              {intelligenceName} {intelligence.percentage.toFixed(1)}%
            </h3>
            <br />
            <p className="text-gray-700 text-sm">
              {intelligenceInfo.definition}
            </p>
          </div>

          {/* Environment section - unchanged */}
          <div className="border-b border-gray-300 p-4">
            <h4 className="font-bold mb-2 text-purple-700">Comfortable Environment</h4>
            <p className="text-gray-700">
              {intelligenceInfo.environment}
            </p>
          </div>

          {/* AAC Methods section - unchanged */}
          <div className="border-b border-gray-300 p-4">
            <h4 className="font-bold mb-2 text-purple-700">AAC Methods</h4>
            <ol className="list-decimal list-inside text-gray-700 space-y-1">
              {intelligenceInfo.aacMethods.map((method, i) => (
                <li key={i}>{method}</li>
              ))}
            </ol>
          </div>

          {/* Activities section - unchanged */}
          <div className="border-b border-gray-300 p-4">
            <h4 className="font-bold mb-2 text-purple-700">Suitable Activities</h4>
            <ActivitiesList
              activities={intelligenceInfo.activities.top5}
              renderContent={() => (
                <ol className="list-decimal list-inside text-gray-700 space-y-1">
                  {intelligenceInfo.activities.top5.map((activity, i) => (
                    <li key={i}>{activity}</li>
                  ))}
                </ol>
              )}
              renderAllContent={() => (
                <ol className="list-decimal list-inside text-gray-700 space-y-1">
                  {intelligenceInfo.activities.all.map((activity, i) => (
                    <li key={i}>{activity}</li>
                  ))}
                </ol>
              )}
            />
          </div>

          {/* Updated Personalized Care Insights Section */}
          <div className="border-b border-gray-300 p-4">
                  <h4 className="font-bold mb-4 text-purple-700">
                    {isFirstIntelligence ? 'Primary Intelligence Summary' : 'Secondary Intelligence Summary'}
                  </h4>
                  {insightsLoading ? (
                    <LoadingSection text={`Generating ${isFirstIntelligence ? 'primary' : 'secondary'} intelligence insights...`} />
                  ) : insightsError ? (
                    <ErrorSection message={insightsError} />
                  ) : (
                    <div className="space-y-2">
                      {personalizedInsights[intelligenceName]?.map((insight, i) => (
                        <InsightItem 
                          key={i}
                          number={i + 1}
                          point={insight.point}
                          rationale={insight.rationale}
                        />
                      ))}
                    </div>)}
                    </div>
        </div>
      );
    })}
  </div>
</div>

{/* Additional Recommendations Section */}
    {/* Recommendations Section */}
    {recommendationsLoading ? (
      <LoadingSection text="Generating recommendations..." />
    ) : recommendationsError ? (
      <ErrorSection message={recommendationsError} />
    ) : recommendationsData?.recommendations ? (
      <div className="text-gray-700 leading-relaxed mt-10">
        {recommendationsData.recommendations.split('\n\n').map((paragraph, index) => (
          <p key={index} className="mb-4">{paragraph}</p>
        ))}
      </div>
    ) : (
      <p className="text-gray-500 italic">No recommendations available.</p>
    )}
    
        {/* Complete Survey Results */}
        <div className="mt-12 space-y-6">
          <h2 className="text-center text-2xl font-bold text-purple-800 mb-8">Survey Results - Percentages for 8 Intelligences</h2>
          <div className="h-[500px] mb-8 border border-gray-200 p-4 bg-gray-50 rounded-lg pt-10 pb-10">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={allIntelligencesData}
                layout="vertical"
                margin={{ top: 5, right: 30, left: 150, bottom: 5 }}
              >
                <CartesianGrid horizontal strokeDasharray="3 3" />
                <XAxis type="number" domain={[0, 100]} />
                <YAxis type="category" dataKey="name" width={140} />
                <Tooltip wrapperStyle={{ zIndex: 9999 }} />
                <Bar dataKey="Percentage" barSize={30} />
              </BarChart>
            </ResponsiveContainer>
          </div>

          {/* Caveat & Disclaimer */}
          <div className="space-y-6 text-gray-700">
            <div>
              <span className="text-teal-600 font-bold">Caveat: </span>
              The goal is to optimize care; just because a person scores higher on one intelligence versus another doesn't mean that he/she should not be exposed to other intelligence-aligned activities.
            </div>

            <div>
              <span className="text-teal-600 font-bold">Disclaimer: </span>
              <em>These results contain educational information. Please use it as a supplement rather than a replacement for medical advice, diagnosis or treatment. These results contain educational information only and should not replace professional medical advice; we do not accept liability for any consequences arising from use of this care plan's recommendations.</em>
              <br></br><br></br>
              <em>Please note that some medications can negatively affect cognition. This survey does not account for these medications' effects.</em>
            </div>

            <div className="text-sm text-gray-600">
              ❉ Multiple Intelligence definitions provided by{' '}
              <a href="https://mybrightwheel.com/" className="text-blue-600 hover:underline">
                https://mybrightwheel.com/
              </a>
              <br />
              The MISPWD© and MĪ Care are based on Dr. Howard Gardner's Theory of Multiple Intelligences.
              <br />
            </div>
            <div className="mx-auto max-w-5xl w-full mb-4">
  <EmailStatusDisplay />
</div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default AICarePlan;
